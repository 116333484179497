import React, { useState } from "react";
import SmsDialog from "./SmsDialog";
import Ticket from "./Ticket";
import TicketsList from "./TicketsList";

interface SmsDialogProps {
  hash?: String;
  queue: Number | undefined;
  close: any;
  type?: string;
}

const Popup: React.FC<SmsDialogProps> = ({ hash, queue, close, type}) => {
  const [isSMS, setSMS] = useState<boolean>(true);
  const [isTicket, setTicket] = useState<boolean>(false);
  const [phone, setPhone] = useState<number | undefined>();
  const [isTicketsList, setTicketsList] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  if (type == "list" && loading) {
    setSMS(false);
    setTicketsList(true);
    setLoading(false);
  }

  function closeSMS(phoneNumber: number) {
    setSMS(false);
    setTicket(true);
    setPhone(phoneNumber);
  }

  return (
    <div className="popupContainer" onClick={(e) => close(e)}>
      <div className="popupContent">
        <div className="closeButton" onClick={(e) => close(e) }>X</div>
        {isSMS ? <SmsDialog hash={hash} queue={queue} onSubmit={closeSMS} /> : <></>}
        {isTicket ? <Ticket hash={hash} queue={queue} phone={phone} /> : <></>}
        {isTicketsList ? <TicketsList /> : <></>}
      </div>
    </div>
  );
};

export default Popup;
