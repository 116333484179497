import React, { createContext, ReactChild, ReactChildren, useContext, useState } from "react";
import { API_KEY, BASE_URL } from "../env";
import useFetch from "../hooks/utilities/useFetch";

interface LayoutContextData {
    description: string;
    image: string;
    imageMobile: string;
    lat: string;
    lng: string
    terminalCatId: string;
    terminalId: string;
    totalWaiting: number;
    setData: (data: any) => void;
}

interface LayoutProviderProps {
    children: ReactChild | ReactChildren;    
}

export const LayoutContext = createContext<LayoutContextData>({} as LayoutContextData);

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {

    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [imageMobile, setImageMobile] = useState('');
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');
    const [terminalCatId, setTerminalCatId] = useState('');
    const [terminalId, setTerminalId] = useState('');
    const [totalWaiting, setTotalWaiting] = useState(0);

    function setData(data: any) {
        setDescription(data.gfsite_desc);
        setImage(data.gfsite_img_inst);
        setImageMobile(data.gfsite_img_mobile);
        setLat(data.gfsite_lat)
        setLng(data.gfsite_lng)
        setTerminalCatId(data.terminalCatID)
        setTerminalId(data.terminalID)
        setTotalWaiting(data.totalwaiting)
    }

    return (
        <LayoutContext.Provider value={{
            description,
            image,
            imageMobile,
            lat,
            lng,            
            terminalCatId,
            terminalId,
            totalWaiting,
            setData
        }}>
            {children}
        </LayoutContext.Provider>
    )
}

export function useLayout() {
    return useContext(LayoutContext);
}