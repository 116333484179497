import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import QueueList from '../components/QueueList';
import { useAuth } from '../context/auth';

import useFetch from '../hooks/utilities/useFetch';
import { API_KEY, BASE_URL } from '../env';
import { Queue } from '../models/queue';
import Popup from '../components/Popup';
import { useLayout } from '../context/layoutContext';
import { useLocalStorage } from '../hooks/utilities/useStorage';

interface HomeProps {
}

const Home: React.FC<HomeProps> = () => {

  const { hash } = useParams();
  const { token } = useAuth();
  const { value } = useFetch<Queue>(`${BASE_URL}getServices.json/${token}/${hash}?api_key=${API_KEY}`, [token]);
  const [tickets] = useLocalStorage("tickets", []);

  const [isPopup, onChangePopup] = useState<boolean>(false);
  const [currentQueue, setCurrentQueue] = useState<number>();
  const [popupType, setPopupType] = useState<string>('sms');
  const { setData } = useLayout();

  function closePopup(e: any) {
    if (e.target.className == "popupContainer" || e.target.className == "closeButton") {
      onChangePopup(false);
    }
  }

  function setPopup(value: boolean, queue: number, type: string = 'sms'){
    setPopupType(type);
    onChangePopup(value);
    setCurrentQueue(queue);
  }

  if (isPopup) {
    document.body.classList.add("removeOverflow");
  } else {
    document.body.classList.remove("removeOverflow");
  }

  useEffect(() => {
    if (value) {
      setData({...value?.site, ...value })
    }
  }, [value]);

  return (
    <main>
        <div className="mainContainer">
          <QueueList hash={hash} queues={value?.queues} setPopup={setPopup} />
          {isPopup && <Popup hash={hash} queue={currentQueue} close={closePopup} type={popupType} />}
        </div>
        {tickets.length > 0 && <button className="tickets-button" onClick={() => setPopup(true, 0, 'list')}>Ver As Minhas Senhas</button>}
    </main>
  );
}

export default Home;
