import React, { useState } from "react";

interface SmsDialogProps {
  hash?: String;
  queue: Number | undefined;
  onSubmit: any;
}

const SmsDialog: React.FC<SmsDialogProps> = ({ hash, queue, onSubmit }) => {
  const [number, onChangeNumber] = useState('');

  return (
    <div className="sms-dialog">
      <p>Por favor insere o teu número de telemóvel para receberes um SMS.</p>
      <p>Para ignorar, pressiona continuar.</p>
      <form>
        <input type="number" id="number" name="number" value={number} title="Phone" onChange={(e) => onChangeNumber(e.target.value)} />
        <button onClick={() => onSubmit(number)}>Continuar</button>
      </form>
    </div>
  );
};

export default SmsDialog;
